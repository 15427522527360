<template>
    <div>
        <slider />

        <search :categories="categories" />

        <categories :categories="categories" :favourites="favourites" />

        <!-- Recent Section -->
        <!-- <section class="property-section">
            <div class="auto-container">
                <div class="sec-title">
                    <span class="title">FIND YOUR HOUSE IN YOUR CITY</span>
                    <h2>RECENT PROPERTIES</h2>
                </div>

                <div class="row">
                    <div class="property-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image-box">
                                <div
                                    class="single-item-carousel owl-carousel owl-theme"
                                >
                                    <figure class="image">
                                        <img
                                            src="images/resource/property-1.jpg"
                                            alt=""
                                        />
                                    </figure>
                                    <figure class="image">
                                        <img
                                            src="images/resource/property-2.jpg"
                                            alt=""
                                        />
                                    </figure>
                                    <figure class="image">
                                        <img
                                            src="images/resource/property-3.jpg"
                                            alt=""
                                        />
                                    </figure>
                                </div>
                                <span class="for">FOR SALE</span>
                                <span class="featured">FEATURED</span>
                                <ul class="info clearfix">
                                    <li>
                                        <a href="properties.html"
                                            ><i
                                                class="la la-calendar-minus-o"
                                            ></i
                                            >2 Years Ago</a
                                        >
                                    </li>
                                    <li>
                                        <a href="agent-detail.html"
                                            ><i class="la la-user-secret"></i
                                            >Ghaly Morca</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div class="lower-content">
                                <ul class="tags">
                                    <li>
                                        <a href="property-detail.html"
                                            >Apartment</a
                                        >,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">Bar</a>,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">House</a
                                        >,
                                    </li>
                                </ul>
                                <h3>
                                    <a href="property-detail.html"
                                        >Single House Near Orland Park.</a
                                    >
                                </h3>
                                <div class="lucation">
                                    <i class="la la-map-marker"></i> Orland
                                    Park, IL 35785, Chicago, United State
                                </div>
                                <ul class="property-info clearfix">
                                    <li>
                                        <i class="flaticon-dimension"></i> 356
                                        Sq-Ft
                                    </li>
                                    <li>
                                        <i class="flaticon-bed"></i> 4 Bedrooms
                                    </li>
                                    <li>
                                        <i class="flaticon-car"></i> 2 Garage
                                    </li>
                                    <li>
                                        <i class="flaticon-bathtub"></i> 3
                                        Bathroom
                                    </li>
                                </ul>
                                <div class="property-price clearfix">
                                    <div class="read-more">
                                        <a
                                            href="property-detail.html"
                                            class="theme-btn"
                                            >More Detail</a
                                        >
                                    </div>
                                    <div class="price">$ 13,65,000</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="property-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image-box">
                                <figure class="image">
                                    <img
                                        src="images/resource/property-2.jpg"
                                        alt=""
                                    />
                                </figure>
                                <span class="for">FOR SALE</span>
                                <span class="featured">FEATURED</span>
                                <ul class="option-box">
                                    <li>
                                        <a
                                            href="images/resource/property-1.jpg"
                                            class="lightbox-image"
                                            data-fancybox="property"
                                            ><i class="la la-camera"></i
                                        ></a>
                                    </li>
                                    <li>
                                        <a href="#"
                                            ><i class="la la-heart"></i
                                        ></a>
                                    </li>
                                    <li>
                                        <a href="#"
                                            ><i class="la la-retweet"></i
                                        ></a>
                                    </li>
                                </ul>
                                <ul class="info clearfix">
                                    <li>
                                        <a href="properties.html"
                                            ><i
                                                class="la la-calendar-minus-o"
                                            ></i
                                            >2 Years Ago</a
                                        >
                                    </li>
                                    <li>
                                        <a href="agent-detail.html"
                                            ><i class="la la-user-secret"></i
                                            >Ghaly Morca</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div class="lower-content">
                                <ul class="tags">
                                    <li>
                                        <a href="property-detail.html"
                                            >Apartment</a
                                        >,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">Bar</a>,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">House</a
                                        >,
                                    </li>
                                </ul>
                                <h3>
                                    <a href="property-detail.html"
                                        >Apartment Morden 1243, W No.</a
                                    >
                                </h3>
                                <div class="lucation">
                                    <i class="la la-map-marker"></i> Orland
                                    Park, IL 35785, Chicago, United State
                                </div>
                                <ul class="property-info clearfix">
                                    <li>
                                        <i class="flaticon-dimension"></i> 356
                                        Sq-Ft
                                    </li>
                                    <li>
                                        <i class="flaticon-bed"></i> 4 Bedrooms
                                    </li>
                                    <li>
                                        <i class="flaticon-car"></i> 2 Garage
                                    </li>
                                    <li>
                                        <i class="flaticon-bathtub"></i> 3
                                        Bathroom
                                    </li>
                                </ul>
                                <div class="property-price clearfix">
                                    <div class="read-more">
                                        <a
                                            href="property-detail.html"
                                            class="theme-btn"
                                            >More Detail</a
                                        >
                                    </div>
                                    <div class="price">$ 13,65,000</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="property-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image-box">
                                <figure class="image">
                                    <img
                                        src="images/resource/property-3.jpg"
                                        alt=""
                                    />
                                </figure>
                                <span class="for">FOR SALE</span>
                                <span class="featured">FEATURED</span>
                                <div class="video-link">
                                    <a
                                        href="https://www.youtube.com/watch?v=Fvae8nxzVz4"
                                        data-fancybox="gallery"
                                        data-caption=""
                                        ><i
                                            class="icon la la-play-circle"
                                            aria-hidden="true"
                                        ></i
                                    ></a>
                                </div>
                                <ul class="info clearfix">
                                    <li>
                                        <a href="properties.html"
                                            ><i
                                                class="la la-calendar-minus-o"
                                            ></i
                                            >2 Years Ago</a
                                        >
                                    </li>
                                    <li>
                                        <a href="agent-detail.html"
                                            ><i class="la la-user-secret"></i
                                            >Ghaly Morca</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div class="lower-content">
                                <ul class="tags">
                                    <li>
                                        <a href="property-detail.html"
                                            >Apartment</a
                                        >,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">Bar</a>,
                                    </li>
                                    <li>
                                        <a href="property-detail.html">House</a
                                        >,
                                    </li>
                                </ul>
                                <h3>
                                    <a href="property-detail.html"
                                        >Great Home for Single fmaily</a
                                    >
                                </h3>
                                <div class="lucation">
                                    <i class="la la-map-marker"></i> Orland
                                    Park, IL 35785, Chicago, United State
                                </div>
                                <ul class="property-info clearfix">
                                    <li>
                                        <i class="flaticon-dimension"></i> 356
                                        Sq-Ft
                                    </li>
                                    <li>
                                        <i class="flaticon-bed"></i> 4 Bedrooms
                                    </li>
                                    <li>
                                        <i class="flaticon-car"></i> 2 Garage
                                    </li>
                                    <li>
                                        <i class="flaticon-bathtub"></i> 3
                                        Bathroom
                                    </li>
                                </ul>
                                <div class="property-price clearfix">
                                    <div class="read-more">
                                        <a
                                            href="property-detail.html"
                                            class="theme-btn"
                                            >More Detail</a
                                        >
                                    </div>
                                    <div class="price">$ 13,65,000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="load-more-btn text-center">
                    <a href="#" class="theme-btn btn-style-two">Load More</a>
                </div>
            </div>
        </section> -->
        <!--End Property Section -->

        <!-- About Us -->
        <!-- <section
            class="about-us"
            style="background-image: url(images/background/1.jpg)"
        >
            <div class="auto-container">
                <div class="row">
                    <div
                        class="info-column col-xl-6 col-lg-12 col-md-12 col-sm-12"
                    >
                        <div class="inner-column">
                            <div class="sec-title light">
                                <span class="title"
                                    >THE BEST PLACE TO FIND THE HOUSE YOU
                                    WANT</span
                                >
                                <h2>WELL TO OURLAND REAL ESTATE</h2>
                            </div>

                            <div class="text">
                                <strong>OURLAND REAL ESTATE</strong> is the best
                                place for elit, sed do eiusmod tempor dolor sit
                                amet, conse ctetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et lorna
                                aliquatd minimam, quis nostrud exercitation oris
                                nisi ut aliquip ex ea.
                            </div>

                            <div class="row features">
                                <div
                                    class="feature-block col-lg-6 col-md-6 col-sm-12"
                                >
                                    <div class="inner-box">
                                        <span
                                            class="icon flaticon-house-1"
                                        ></span>
                                        <h4>
                                            <a href="about.html"
                                                >Buy Property</a
                                            >
                                        </h4>
                                        <div class="text">
                                            We have the best properties Sale,
                                            Buy, and Rent Dealers.
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="feature-block col-lg-6 col-md-6 col-sm-12"
                                >
                                    <div class="inner-box">
                                        <span class="icon flaticon-rent"></span>
                                        <h4>
                                            <a href="about.html"
                                                >REnt Property</a
                                            >
                                        </h4>
                                        <div class="text">
                                            We have the best properties Sale,
                                            Buy, and Rent Dealers.
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="feature-block col-lg-6 col-md-6 col-sm-12"
                                >
                                    <div class="inner-box">
                                        <span
                                            class="icon flaticon-house-5"
                                        ></span>
                                        <h4>
                                            <a href="about.html"
                                                >Real Estate Kit</a
                                            >
                                        </h4>
                                        <div class="text">
                                            We have the best properties Sale,
                                            Buy, and Rent Dealers.
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="feature-block col-lg-6 col-md-6 col-sm-12"
                                >
                                    <div class="inner-box">
                                        <span
                                            class="icon flaticon-apartment"
                                        ></span>
                                        <h4>
                                            <a href="about.html"
                                                >Sale Property</a
                                            >
                                        </h4>
                                        <div class="text">
                                            We have the best properties Sale,
                                            Buy, and Rent Dealers.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="video-column col-xl-6 col-lg-7 col-md-12 col-sm-12"
                    >
                        <div class="inner-column">
                            <div class="video-box">
                                <figure class="image">
                                    <img
                                        src="images/resource/image-2.jpg"
                                        alt=""
                                    />
                                </figure>
                                <a
                                    href="https://www.youtube.com/watch?v=Fvae8nxzVz4"
                                    class="play-now"
                                    data-fancybox="gallery"
                                    data-caption=""
                                    ><i
                                        class="icon la la-play"
                                        aria-hidden="true"
                                    ></i
                                    ><span class="ripple"></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- About Us -->

        <!--Popular Places Section-->
        <!-- <section class="popular-places-section">
            <div class="auto-container">
                <div class="sec-title">
                    <span class="title"
                        >FIND YOUR DREAM HOUSE IN YOUR CITY</span
                    >
                    <h2>MOST POPULAR PLACES</h2>
                </div>

                <div class="masonry-items-container clearfix">
                    <div class="popular-item masonry-item medium-item">
                        <div class="image-box">
                            <figure class="image">
                                <img src="images/gallery/1-1.jpg" alt="" />
                            </figure>
                            <div class="info-box">
                                <span class="category">Apartment</span>
                                <h3 class="place">
                                    <a href="properties.html">New York City</a>
                                </h3>
                                <div class="properties">
                                    <a href="properties.html">7 Properties</a>
                                </div>
                                <div class="view-all">
                                    <a href="properties.html">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popular-item masonry-item medium-item">
                        <div class="image-box">
                            <figure class="image">
                                <img src="images/gallery/1-2.jpg" alt="" />
                            </figure>
                            <div class="info-box">
                                <span class="category">House</span>
                                <h3 class="place">
                                    <a href="properties.html">Los Angeles</a>
                                </h3>
                                <div class="properties">
                                    <a href="properties.html">9 Properties</a>
                                </div>
                                <div class="view-all">
                                    <a href="properties.html">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popular-item masonry-item medium-item">
                        <div class="image-box">
                            <figure class="image">
                                <img src="images/gallery/1-3.jpg" alt="" />
                            </figure>
                            <div class="info-box">
                                <span class="category">Restaurant</span>
                                <h3 class="place">
                                    <a href="properties.html">Singapore</a>
                                </h3>
                                <div class="properties">
                                    <a href="properties.html">5 Properties</a>
                                </div>
                                <div class="view-all">
                                    <a href="properties.html">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popular-item masonry-item small-item">
                        <div class="image-box">
                            <figure class="image">
                                <img src="images/gallery/1-4.jpg" alt="" />
                            </figure>
                            <div class="info-box">
                                <span class="category">Farm</span>
                                <h3 class="place">
                                    <a href="properties.html">Amalfi Coast</a>
                                </h3>
                                <div class="properties">
                                    <a href="properties.html">12 Properties</a>
                                </div>
                                <div class="view-all">
                                    <a href="properties.html">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popular-item masonry-item small-item">
                        <div class="image-box">
                            <figure class="image">
                                <img src="images/gallery/1-5.jpg" alt="" />
                            </figure>
                            <div class="info-box">
                                <span class="category">Villa</span>
                                <h3 class="place">
                                    <a href="properties.html">Miami</a>
                                </h3>
                                <div class="properties">
                                    <a href="properties.html">6 Properties</a>
                                </div>
                                <div class="view-all">
                                    <a href="properties.html">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="load-more-btn text-center">
                    <a href="#" class="theme-btn btn-style-two">Load More</a>
                </div>
            </div>
        </section> -->
        <!--Portfolio Section-->

        <!-- Call To Action -->
        <!-- <section
            class="call-to-action"
            style="background-image: url(images/background/2.jpg)"
        >
            <div class="auto-container">
                <div class="clearfix">
                    <div class="title-column">
                        <div class="sec-title light">
                            <span class="title"
                                >IN FEW SECONDS WITH WILLES</span
                            >
                            <h2>BUY OR SALE YOUR HOUSE</h2>
                        </div>
                    </div>

                    <div class="button-column">
                        <a
                            href="admin/submit-property.html"
                            class="theme-btn btn-style-three"
                            >SUBMIT PROPERTY</a
                        >
                        <a href="#" class="theme-btn btn-style-one"
                            >BROWSE PROPERTY</a
                        >
                    </div>
                </div>
            </div>
        </section> -->
        <!--End Call To Action -->
    </div>
</template>

<script>
import Search from "../components/Search.vue";
import Slider from "../components/Slider.vue";
import categories from "../components/Categories.vue";
export default {
    name: "Home",
    components: { slider: Slider, search: Search, categories },
    data() {
        return {
            favourites: [],
            categories: [],
        };
    },
    mounted() {
        this.$axios
            .get("/categories?withProperties=true")
            .then((res) => {
                this.categories = res.data.categories;
                this.favourites = res.data.favourites ?? [];
            })
            .then(() => {
                $(".filter-list").mixItUp({});
            });
    },
};
</script>
