<template>
    <!-- Property Search Section -->
    <section class="property-search-section">
        <div class="auto-container">
            <div class="property-search-form">
                <form method="post">
                    <div class="row">
                        <!-- Form Group -->
                        <div class="form-group col-lg-3 col-md-6 col-sm-12">
                            <label>بحث نصى</label>
                            <input
                                v-model="searchParams.q"
                                type="text"
                                placeholder="بحث باسم العقار او العنوان"
                            />
                        </div>

                        <!-- Form Group -->
                        <div class="form-group col-lg-3 col-md-6 col-sm-12">
                            <label>عدد الغرف</label>
                            <select
                                v-model="searchParams.rooms"
                                class="custom-select-box"
                            >
                                <option value="0">عدد الغرف</option>
                                <option value="1">غرفة واحدة</option>
                                <option value="2">غرفتين</option>
                                <option value="3">3 غرف</option>
                                <option value="4">4 غرف</option>
                                <option value="5">5 غرف</option>
                            </select>
                        </div>

                        <!-- Form Group -->
                        <div class="form-group col-lg-3 col-md-6 col-sm-12">
                            <div class="range-slider-one clearfix">
                                <label>بحث بالسعر من - إلى</label>
                                <div class="price-range-slider"></div>
                                <div class="input">
                                    <input
                                        type="text"
                                        class="price-amount"
                                        name="field-name"
                                        readonly
                                        :value="
                                            searchParams.price_from +
                                            ' - ' +
                                            searchParams.price_to
                                        "
                                    />
                                </div>
                                <div class="title">ريال عمانى</div>
                            </div>
                        </div>

                        <!-- Form Group -->
                        <div class="form-group col-lg-3 col-md-6 col-sm-12">
                            <button
                                type="submit"
                                class="theme-btn btn-style-two"
                                @click.prevent="search"
                            >
                                بحث
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!-- End Property Search Section -->
</template>

<script>
export default {
    name: "search",
    props: ["categories"],
    data() {
        return {
            searchParams: {
                price_from: 0,
                price_to: 0,
                rooms: 0,
                order: "price-asc",
                q: "",
            },
        };
    },
    mounted() {
        $(".price-range-slider").slider({
            range: true,
            min: 0,
            max: 10000,
            values: [this.searchParams.price_from, this.searchParams.price_to],
            slide: (event, ui) => {
                this.searchParams.price_from = ui.values[0];
                this.searchParams.price_to = ui.values[1];
            },
        });
    },
    methods: {
        search() {
            this.$router.push({
                name: "website.properties",
                query: this.searchParams,
            });
        },
    },
};
</script>

<style scoped>
input.price-amount {
    direction: ltr;
}
</style>