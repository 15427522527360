<template>
    <section class="banner-section style-two">
        <div class="banner-carousel owl-carousel owl-theme">
            <div
                v-for="(slider, index) in sliders"
                :key="index"
                class="slide-item"
                :style="
                    'background-image: url(' +
                    slider.image +
                    ');' +
                    (slider.link ? `cursor: pointer;` : '')
                "
                :onclick="slider.link ? `window.location='${slider.link}'` : ''"
            >
                <div class="auto-container">
                    <div class="inner-box">
                        <div class="content-box">
                            <h2>{{ slider.text }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "slider",
    data() {
        return {
            sliders: [],
        };
    },
    mounted() {
        this.$axios.get("/sliders").then((res) => {
            this.sliders = res.data.sliders;

            setTimeout(() => {
                this.prepareSlider();
            }, 0);
        });
    },
    methods: {
        prepareSlider() {
            //Banner Carousel
            if (this.sliders.length) {
                $(".banner-carousel").owlCarousel({
                    animateOut: "fadeOutDown",
                    animateIn: "fadeInDown",
                    loop: true,
                    margin: 30,
                    nav: true,
                    mouseDrag: false,
                    touchDrag: false,
                    smartSpeed: 15000,
                    autoHeight: true,
                    autoplay: true,
                    autoplayTimeout: 7000,
                    navText: [
                        '<span class="la la-angle-left"></span>',
                        '<span class="la la-angle-right"></span>',
                    ],
                    dots: false,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        600: {
                            items: 1,
                        },
                        1024: {
                            items: 1,
                        },
                    },
                });
            }
        },
    },
};
</script>

<style>
.owl-prev, .owl-next {
    background: transparent !important;
    color: #ccc !important;
    font-size: 50px !important;
}
</style>