<template>
    <!-- Property Filter Section -->
    <section class="property-filter-section">
        <div class="auto-container">
            <!--MixitUp Galery-->
            <div class="mixitup-gallery">
                <div class="upper-box clearfix">
                    <div class="sec-title">
                        <span class="title">هل تبحث عن مكان إقامة ؟</span>
                        <h2>أماكن إقامة مميزة</h2>
                    </div>

                    <!--Filter-->
                    <div class="filters">
                        <ul class="filter-tabs filter-btns clearfix">
                            <li
                                class="active filter"
                                data-role="button"
                                data-filter=".all"
                            >
                                الكل
                            </li>
                            <li
                                v-for="(category, index) in categories"
                                :key="index"
                                class="filter"
                                data-role="button"
                                :data-filter="'.category-' + category.id"
                            >
                                {{ category.title }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="filter-list row">
                    <template v-for="category in categories">
                        <!-- Property Block -->
                        <div
                            v-for="property in category.random_properties"
                            :key="property.id"
                            :class="
                                'property-block all mix col-xl-4 col-lg-6 col-md-6 col-sm-12 category-' +
                                property.category_id
                            "
                        >
                            <div class="inner-box">
                                <div class="image-box">
                                    <figure
                                        @click="
                                            changeLocation(
                                                `/property/${property.id}`
                                            )
                                        "
                                        class="image pointer"
                                    >
                                        <img :src="property.image_url" alt="" />
                                    </figure>

                                    <!-- <span class="featured">FEATURED</span> -->
                                    <ul class="info clearfix">
                                        <li class="text-left">
                                            <div class="rating text-info">
                                                <span
                                                    :class="
                                                        property.average_overall_rating >=
                                                        1
                                                            ? 'la la-star'
                                                            : 'la la-star-o'
                                                    "
                                                ></span>
                                                <span
                                                    :class="
                                                        property.average_overall_rating >=
                                                        2
                                                            ? 'la la-star'
                                                            : 'la la-star-o'
                                                    "
                                                ></span>
                                                <span
                                                    :class="
                                                        property.average_overall_rating >=
                                                        3
                                                            ? 'la la-star'
                                                            : 'la la-star-o'
                                                    "
                                                ></span>
                                                <span
                                                    :class="
                                                        property.average_overall_rating >=
                                                        4
                                                            ? 'la la-star'
                                                            : 'la la-star-o'
                                                    "
                                                ></span>
                                                <span
                                                    :class="
                                                        property.average_overall_rating >=
                                                        5
                                                            ? 'la la-star'
                                                            : 'la la-star-o'
                                                    "
                                                ></span>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="option-box">
                                        <li>
                                            <a
                                                :href="property.image_url"
                                                class="lightbox-image"
                                                data-fancybox="property"
                                                ><i class="la la-camera"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a
                                                @click.prevent="
                                                    toggleFavourite(property.id)
                                                "
                                                ><i
                                                    :class="
                                                        'la la-heart' +
                                                        (favs.includes(
                                                            property.id
                                                        )
                                                            ? ' text-danger'
                                                            : '')
                                                    "
                                                ></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="lower-content">
                                    <ul class="tags">
                                        <li>
                                            <router-link
                                                :to="
                                                    '/properties?category=' +
                                                    category.id
                                                "
                                                >{{
                                                    category.title
                                                }}</router-link
                                            >
                                        </li>
                                    </ul>
                                    <!-- <div class="thumb">
                    <img src="images/resource/thumb-5.jpg" alt="" />
                  </div> -->
                                    <h3>
                                        <router-link
                                            :to="'/property/' + property.id"
                                            >{{ property.title }}</router-link
                                        >
                                    </h3>
                                    <div class="lucation">
                                        <i class="la la-map-marker"></i>
                                        {{
                                            "عمان - " +
                                            property.address +
                                            (property.area
                                                ? " - " + property.area
                                                : "") +
                                            (property.street
                                                ? " - " + property.street
                                                : "") +
                                            (property.landmark
                                                ? " - " + property.landmark
                                                : "")
                                        }}
                                    </div>
                                    <ul class="property-info clearfix">
                                        <li>
                                            <i class="flaticon-users"></i>
                                            {{ property.guests }} أشخاص
                                        </li>
                                        <li>
                                            <i class="flaticon-bed"></i
                                            >{{ property.beds }} أسرة
                                        </li>
                                        <!-- <li>
                                            <i class="la la-wifi"></i>
                                            {{
                                                property.wifi
                                                    ? "متوفر"
                                                    : "غير متوفر"
                                            }}
                                        </li>
                                        <li>
                                            <i class="flaticon-bathtub"></i>
                                            {{ property.bathrooms }} حمامات
                                        </li> -->
                                    </ul>
                                    <div class="property-price clearfix">
                                        <div class="read-more">
                                            <router-link
                                                :to="'/property/' + property.id"
                                                class="theme-btn"
                                                >التفاصيل</router-link
                                            >
                                        </div>
                                        <div class="price">
                                            {{ property.price_1 }} ريال
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- Load More -->
                <div class="load-more-btn text-center">
                    <router-link
                        to="/properties"
                        class="theme-btn btn-style-two"
                        >المزيد</router-link
                    >
                </div>
            </div>
        </div>
    </section>
    <!--End Property Filter Section -->
</template>

<script>
export default {
    name: "categries",
    props: ["categories", "favourites"],
    data() {
        return {
            favs: this.favourites,
        };
    },
    mounted() {},
    methods: {
        toggleFavourite(property_id) {
            this.$axios
                .patch("/my/toggle-property-favourite/" + property_id)
                .then((res) => {
                    this.favs = res.data.favourites;
                });
        },
        changeLocation(to) {
            this.$router.push(to);
        },
    },
    watch: {
        favourites(n, o) {
            this.favs = n;
        },
    },
};
</script>

<style scoped>
.property-filter-section .property-block .image-box .info {
    bottom: 0;
    direction: ltr;
}

.property-block .image-box .image img {
    aspect-ratio: 3/2.5 !important;
}

.pointer {
    cursor: pointer;
}
</style>